import _near from "./near";
import _wallet_account from "./wallet_account";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WalletConnection = exports.ConnectedWalletAccount = exports.Near = void 0;
var near_1 = _near;
Object.defineProperty(exports, "Near", {
  enumerable: true,
  get: function () {
    return near_1.Near;
  }
});
var wallet_account_1 = _wallet_account;
Object.defineProperty(exports, "ConnectedWalletAccount", {
  enumerable: true,
  get: function () {
    return wallet_account_1.ConnectedWalletAccount;
  }
});
Object.defineProperty(exports, "WalletConnection", {
  enumerable: true,
  get: function () {
    return wallet_account_1.WalletConnection;
  }
});
export default exports;
export const __esModule = exports.__esModule,
  WalletConnection = exports.WalletConnection,
  ConnectedWalletAccount = exports.ConnectedWalletAccount,
  Near = exports.Near;